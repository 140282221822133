.wrap {
  padding: 70px 0 20px;
  background-color: #222222;
  color: #fff;
  width: 100%;
  bottom: 0;
  position: relative;
  z-index: 1;
  user-select: none; /* Prevent text selection */

  @media (min-width: 990px) {
    z-index: -1;
    position: fixed;
  }

  .content {
    text-align: center;
    margin: 0 auto;
    padding: 0 12px;

    @media (min-width: 576px) {
      max-width: 540px;
      padding: 0;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 990px) {
      max-width: 945px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    @media (min-width: 1400px) {
      max-width: 1300px;
    }

    .logo {
      width: 32px;
    }

    p {
      font-size: 16px;
      line-height: 1.6;
      color: #ffffff;
      text-align: left;
    }

    .share-method {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0 50px;
      gap: 10px;

      .share-item {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #777777;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s;

        img {
          width: 18px; /* Adjust icon size as needed */
          height: 18px; /* Adjust icon size as needed */
          filter: invert(100%); /* Invert the color for better visibility */
        }

        &:hover {
          background-color: #50aceb;
        }
      }
    }

    .main {
      display: flex;
      flex: 1;
      text-align: left;
      gap: 20px;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .left-side,
      .right-side {
        flex: 1; /* Ensure both sides take equal width */
      }

      .left-side {
        display: flex;
        gap: 20px; /* Optional: space between the two item lists */

        .item-list {
          flex: 1;
        }
      }

      .right-side {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0; /* Remove padding */

        @media (min-width: 768px) {
          align-items: center;
        }

        .logo {
          margin-bottom: 20px;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
      margin-top: 70px;
      width: 100%;
      text-align: center;
      border-top: 1px dotted #ffffff;
      padding-top: 20px;

      span {
        font-weight: 700;
        color: #50aceb;
      }
    }
  }
}
