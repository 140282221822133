@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400&display=swap");

/* Reset CSS to ensure consistency across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base styles */
html {
  font-size: 16px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased; /* Chrome, Safari */
  -moz-osx-font-smoothing: grayscale; /* Firefox */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  color: #777777;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Global typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  color: #333333;
}

h1 {
  font-size: 2rem;
}

p {
  margin-bottom: 1rem;
  color: #777777;
}

/* Utility classes */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
