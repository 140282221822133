.starfield {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.starfield > * {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-image: radial-gradient(
      2px 2px at 40px 60px,
      #cccccc,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(2px 2px at 20px 50px, #dddddd, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 30px 100px, #eeeeee, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 40px 60px, #ffffff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 110px 90px, #cccccc, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 190px 150px, #dddddd, rgba(0, 0, 0, 0));
  background-image: radial-gradient(
      2px 2px at 40px 60px,
      #cccccc,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(2px 2px at 20px 50px, #dddddd, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 30px 100px, #eeeeee, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 40px 60px, #ffffff, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 110px 90px, #cccccc, rgba(0, 0, 0, 0)),
    radial-gradient(2px 2px at 190px 150px, #dddddd, rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 200px 200px;
  animation-name: starfieldRotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.starfield > *:nth-child(1) {
  animation-duration: 18s;
}

.starfield > *:nth-child(2) {
  animation-duration: 22s;
}

.starfield > *:nth-child(3) {
  animation-duration: 36s;
}

.starfield > *:nth-child(4) {
  animation-duration: 40s;
}

@keyframes starfieldRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ast_waves {
  position: absolute;
  width: 800px;
  bottom: -75px;
  left: 0%;
  margin-left: -400px;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
}

.ast_waves2 {
  position: absolute;
  width: 100%;
  top: 385px;
  right: 0px;
  margin: 0px auto;
  left: 0px;
}

.ast_waves3 {
  position: absolute;
  width: 900px;
  bottom: 15px;
  right: -400px;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
}

.ast_wave {
  width: 700px;
  height: 700px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin-left: 50%;
  left: -350px;
  position: absolute;
  bottom: -350px;
  z-index: -1;
  transform: scale(0.1, 0.1);
  -moz-transform: scale(0.1, 0.1);
  -ms-transform: scale(0.1, 0.1);
  -o-transform: scale(0.1, 0.1);
  transform: scale(0.1, 0.1);
  opacity: 0;
  animation: pulse 3000ms ease-out infinite;
  -moz-animation: pulse 3000ms ease-out infinite;
  -ms-animation: pulse 3000ms ease-out infinite;
  -o-animation: pulse 3000ms ease-out infinite;
  animation: pulse 3000ms ease-out infinite;
}

.ast_wave:nth-of-type(2) {
  animation-delay: 600ms;
  -moz-animation-delay: 600ms;
  -ms-animation-delay: 600ms;
  -o-animation-delay: 600ms;
  animation-delay: 600ms;
}

.ast_wave:nth-of-type(3) {
  animation-delay: 1200ms;
  -moz-animation-delay: 1200ms;
  -ms-animation-delay: 1200ms;
  -o-animation-delay: 1200ms;
  animation-delay: 1200ms;
}

@keyframes pulse {
  0% {
    transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulse-text {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse-text {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
