.wrap {
  width: 100%;
  padding: 70px 0 130px;
  background: #fff;
  user-select: none; /* Prevent text selection */

  .header {
    margin: 0 auto 50px;
    text-align: center;
    max-width: 700px;

    .title {
      text-transform: capitalize;
      font-weight: 600;
      line-height: 1.1;
      color: #333333;
      margin: 0;
      font-size: 28px;

      @media (min-width: 576px) {
        font-size: 40px;
      }

      .name {
        color: #50aceb;
      }
    }

    p {
      font-size: 15px;
      text-align: center;
      line-height: 1.5;
      color: #777777;
      padding: 0 12px;

      @media (min-width: 576px) {
        font-size: 16px;
      }
    }
  }

  .main {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 16px;
    text-align: center;

    @media (min-width: 576px) {
      flex-direction: column;
      max-width: 540px;
      padding: 0;
      text-align: left;
    }

    @media (min-width: 768px) {
      flex-direction: column;
      max-width: 720px;
    }

    @media (min-width: 990px) {
      gap: 40px;
      max-width: 960px;
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      gap: 60px;
      max-width: 1140px;
    }

    @media (min-width: 1440px) {
      gap: 100px;
      max-width: 1230px;
    }

    .pic {
      width: 350px;
      height: 350px;

      @media (min-width: 576px) {
        width: 400px;
        height: 400px;
      }
    }

    h4 {
      margin: 0 auto 15px;
      font-size: 20px;
      text-transform: capitalize;
    }

    p {
      font-size: 15px;
      line-height: 1.5;
      color: #777777;
    }
  }
}
