// src/components/UpdateBirthDetails/index.module.scss */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* Light grey for background */
  text-align: center;
  height: auto;
  padding: 2%;
  font-family: "Arial", sans-serif;

  .header {
    margin-top: 10%;
    text-align: center;
    margin-bottom: 20px;

    h1 {
      color: #003366; /* Dark blue */
      font-size: 2rem; /* Larger heading */
      font-weight: bold; /* Bold heading */

      @media (max-width: 768px) {
        // invisible on mobile
        display: none;
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Increased gap for better readability */
    width: 100%;
    max-width: 500px; /* Centered form with a max-width */
    padding: 20px;
    background-color: #ffffff;

    h2 {
      color: #003366; /* Dark blue */
      font-size: 1.5rem; /* Larger heading */
      font-weight: bold; /* Bold heading */
      margin-top: 2%;

      @media (min-width: 768px) {
        // invisible on desktop
        display: none;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
    }

    label {
      display: flex;
      flex-direction: column;
      color: #003366; /* Dark blue */
      font-weight: bold; /* Bold labels */
      // display at the left of the line
      text-align: left;
    }

    input[type="text"],
    input[type="email"],
    select {
      appearance: none; /* Remove default styling */
      -webkit-appearance: none; /* Remove default styling for Safari */
      -moz-appearance: none; /* Remove default styling for Firefox */
      background-color: #ffffff;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 8px;
      font-size: 1rem;
      color: #333; /* Dark grey text color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:disabled {
        background-color: #f0f0f0; /* Light grey background for disabled state */
        border-color: #ccc; /* Match the border color */
        color: #999; /* Grey text color for disabled state */
      }
    }

    input[type="text"]:hover,
    input[type="email"]:hover {
      border-color: #00509e; /* Hover effect */
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
    }

    input[type="text"]:focus,
    input[type="email"]:focus,
    select:focus {
      border-color: #00509e; /* Custom focus border color */
      box-shadow: 0 0 8px rgba(0, 80, 158, 0.3); /* Custom focus box shadow */
      outline: none; /* Remove default outline */
    }

    .date-of-birth,
    .time-of-birth {
      display: flex;
      gap: 10px;
    }

    .date-of-birth select,
    .time-of-birth select {
      flex: 1;
    }

    .date-select:focus,
    .time-select:focus {
      border-color: #00509e;
      box-shadow: 0 0 8px rgba(0, 80, 158, 0.3);
      outline: none;
    }

    .exact-time-checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #003366; /* Dark blue */
    }

    button {
      padding: 10px 20px;
      background-color: #003366; /* Dark blue */
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.3s ease;
      font-size: 1rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Button shadow */

      &:hover {
        background-color: #00509e; /* Slightly lighter blue */
        transform: translateY(-2px); /* Subtle lift effect */
      }
    }
  }
}
