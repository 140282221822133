.aboutPage {
  margin-top: 100px;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 1.6;

  h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #2c3e50;
  }

  p {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #333333;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      padding-left: 1.2em;
      position: relative;

      &::before {
        content: "✔";
        position: absolute;
        left: 0;
        color: #2c3e50;
      }

      strong {
        font-weight: 600;
      }
    }
  }
}

.heroSection {
  background: linear-gradient(to right, #2980b9, #6dd5fa);
  color: white;
  padding: 80px 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;

  h1 {
    font-size: 3em;
    margin-bottom: 20px;
    animation: fadeInDown 1s ease;
  }

  p {
    font-size: 1.3em;
    max-width: 700px;
    margin: 0 auto;
    animation: fadeInUp 1s ease;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.missionSection,
.whatWeDoSection,
.featuresSection,
.commitmentSection,
.joinUsSection {
  padding: 60px 20px;
  margin-top: 20px;
  background-color: #ecf0f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:nth-child(even) {
    background-color: #bdc3c7;
  }

  h2 {
    margin-bottom: 20px;
    position: relative;

    &::after {
      content: "";
      width: 50px;
      height: 4px;
      background: #2c3e50;
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }

  p,
  ul {
    max-width: 800px;
    margin: 0 auto;
    animation: fadeIn 1s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.joinUsSection {
  text-align: center;

  .joinButton {
    background-color: #2c3e50;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1.1em;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 20px;

    &:hover {
      background-color: #1a242f;
      transform: translateY(-5px);
    }
  }
}
