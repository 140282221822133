.form-wrap {
  display: flex;
  justify-content: center;

  .life-overview-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (min-width: 400px) {
      align-items: flex-start;
    }

    .form-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .select-gender {
        width: 192px;
        height: 42px;
        text-shadow: none;
      }

      ::placeholder {
        font-size: 14px !important; /* For Dob and Tob only */
      }
    }

    label {
      color: #dddddd;
      font-weight: 900;
      width: 130px;
      margin: 10px;
      font-size: 18px;
      margin-right: 15px;
    }

    input {
      border-radius: 4px;
      font-size: 16px;
      width: 150px;
      background-color: #fff;
    }
  }

  .result {
    background-color: rgba(0, 0, 0, 0.6); // Semi-transparent black background
    color: #ffffff; // White text color
    padding: 20px;
    margin: 0 auto; // Centering if the parent is flex or grid
    margin-left: 10%; // Left margin
    margin-right: 10%; // Right margin
    border-radius: 10px; // Rounded corners
    font-family: "Merriweather", serif; // A font that feels a bit mystical
    font-size: 1rem;
    line-height: 1.6; // Space between lines for readability
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); // Soft shadow for depth

    // Blurred background effect:
    backdrop-filter: blur(
      5px
    ); // This will blur the background behind the text container

    p {
      color: #e7e7e7;
      font-size: 1.2rem;
    }
  }

  .submitBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .startChatBtn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 35%;
  }
}
