.wrap {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 576px) {
    width: 70%; /* 改为 100%，确保在小屏幕上显示良好 */
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 80%;
  }

  @media (min-width: 1200px) {
    width: 60%;
  }

  .title {
    margin-bottom: 20px; /* 为标题添加一些底部间距 */
  }

  .item-list {
    width: 100%;

    .item {
      float: left;
      width: 100%;
      color: #ffffff;
      text-transform: capitalize;
      padding: 5px 0px 7px 0px;
      border-bottom: 1px solid #444444;
      cursor: pointer;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: #50aceb;
      }
    }
  }
}

.title {
  margin-top: 0px;
  margin-bottom: 25px;
  font-size: 20px;
  color: #ffffff;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 15px;
  font-weight: 600;
  line-height: 1.1;

  &::after {
    content: "";
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 70px;
    background-color: #50aceb;
    height: 2px;
  }
}
