/* src/components/VerifyEmail/VerifyEmail.module.scss */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;800&display=swap");

.verify-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  text-align: center;
  height: 100vh;
  padding-bottom: 1%;

  h1 {
    font-family: "Inter", sans-serif;
    font-weight: 800; // Font weight for h1
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-family: "Inter", sans-serif;
    font-weight: 500; // Font weight for p
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
    max-width: 600px;

    @media (max-width: 768px) {
      max-width: 400px;
    }
  }

  .message {
    color: #3c763d;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .error {
    color: #d9534f;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .resend-button {
    background-color: #43b0f1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
      background-color: #057dcd;
      transform: translateY(-2px);
    }

    &:active {
      background-color: #1e3d58;
      transform: translateY(0);
    }
  }

  .resend-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
}
