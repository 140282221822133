.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  text-align: center;
  h1 {
    font-family: "Poppins", sans-serif; /* Use a professional and clean font */
    font-size: 2rem; /* Set the font size */
    font-weight: 700; /* Make the font bold */
    margin-top: 18vh;
    text-align: center; /* Center align the title */
    color: #333;
  }
  .custom-signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    padding: 20px;
    background-color: white;
    // border-radius: 8px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    form {
      display: flex;
      flex-direction: column;
      width: 100%;

      input {
        margin-bottom: 20px;
        padding: 15px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
          border-color: #50aceb;
          box-shadow: 0 0 8px rgba(40, 167, 69, 0.1);
          outline: none;
        }
      }

      .error {
        display: flex;
        align-items: center;
        color: #d9534f;
        background-color: #f2dede;
        border: 1px solid #ebccd1;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 500;
      }
      .message {
        color: #3c763d;
        background-color: #dff0d8;
        border: 1px solid #d6e9c6;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 500;
      }

      button {
        padding: 15px;
        background-color: #43b0f1;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s, transform 0.3s;

        &:hover {
          background-color: #057dcd;
          transform: translateY(-2px);
        }

        &:active {
          background-color: #1e3d58;
          transform: translateY(0);
        }
      }
    }

    .signin-link {
      margin-top: 20px;
      font-size: 14px;
      text-align: center;

      a {
        color: #057dcd;
        text-decoration: none;
        font-weight: 500;
        transition: color 0.3s;

        &:hover {
          color: #1e3d58;
        }
      }
    }
    .divider {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 400px;
      font-size: 1rem;
      color: #888;
      margin-block: 15px;

      .line {
        flex: 1;
        height: 1px;
        background-color: #ccc;
        margin: 0 5px;
      }
    }
  }
}
