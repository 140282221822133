@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.starField {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.overlay {
  position: relative;
  width: 100%;
  padding: 100px 0;
  background-color: rgba(0, 0, 0, 0.6);
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  color: #fff;
  box-sizing: border-box;

  .header {
    margin: 0 auto;
    text-align: center;
    padding: 0 16px;

    @media (max-width: 575px) {
      h1 {
        font-size: 30px;
      }
      p {
        text-align: center;
        font-size: 16px;
        margin: 30px auto;
        width: 90%;
      }
    }

    @media (min-width: 576px) {
      max-width: 100%;
      p {
        text-align: center;
        font-size: 16px;
        margin: 30px auto;
        width: 100%;
        width: 90%;
      }
    }

    @media (min-width: 768px) {
      max-width: 100%;
      p {
        text-align: center;
        font-size: 20px;
        margin: 30px auto;
        width: 80%;
      }
    }

    @media (min-width: 992px) {
      max-width: 960px;
      p {
        text-align: center;
        font-size: 20px;
        margin: 30px auto;
        width: 80%;
      }
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
      p {
        text-align: center;
        font-size: 20px;
        margin: 30px auto;
        width: 60%;
      }
    }

    @media (min-width: 1400px) {
      max-width: 1300px;
      p {
        text-align: center;
        font-size: 20px;
        margin: 30px auto;
        width: 60%;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;

      span {
        color: #50aceb;
      }
    }

    .form-description {
      text-align: center;
      font-size: 18px;
      color: #fff;
    }
  }

  .controls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
}
