.action-handler {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: #555;
  }
}
