.wrap {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  background-color: #000;
  background-image: url("../../../public/images/banner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 576px) {
    height: 100vh;
  }

  @media (max-width: 576px) {
    background-image: none;
  }

  .banner {
    height: 100%;
    width: 100%;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 100px 0px;
    background-color: rgba(
      0,
      0,
      0,
      0.6
    ); /* Semi-transparent background color */

    @media (min-width: 576px) {
      padding: 250px 0px;
    }
  }

  .content {
    margin: 0 auto;
    position: relative;
    //backdrop-filter: blur(3px);
    padding: 10px;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
    border-radius: 10px; /* Add rounded corners */
    color: #fff;
    max-width: 350px;
    user-select: none; /* Prevent text selection */

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    @media (min-width: 1440px) {
      max-width: 1300px;
    }

    h1.title {
      line-height: 1.1;
      margin: 5px 0px 0px 0px;
      color: #fff;
      font-size: 36px;
      font-weight: 400;

      @media (min-width: 460px) {
        font-size: 38px;
      }

      @media (min-width: 576px) {
        font-size: 42px;
      }

      @media (min-width: 768px) {
        font-size: 48px;
      }

      @media (min-width: 992px) {
        font-size: 52px;
      }
    }

    .container {
      font-size: 18px;
      margin: 40px 0 50px;
      font-family: "Philosopher", sans-serif;
      color: #fff;
      display: flex;
      justify-content: center;
      gap: 0px;
      flex-wrap: wrap;

      @media (min-width: 576px) {
        gap: 25px;
        flex-wrap: nowrap;
      }

      .item-list {
        display: flex;
        gap: 25px;
      }

      .item {
        position: relative;

        &:first-of-type {
          &::after {
            content: "*";
            position: absolute;
            top: 3px;
            right: -18px;
          }
        }
      }

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }
  }
}
