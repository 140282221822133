.nav-list-wrap {
  display: none;

  @media (min-width: 990px) {
    display: flex;
  }
}

.nav-list {
  display: flex;

  a {
    text-decoration: none;
    color: #fff;
  }
}

.login-wrap {
  display: flex;
  margin-left: 30px;
}

.nav-item {
  font-size: 16px;
  color: #ffffff;
  padding: 10px 15px;
  border: 1.7px solid transparent;

  &:hover {
    border: 1.7px solid #50aceb;
    border-radius: 5px;
  }

  cursor: pointer;
}

.nav-login-button {
  background: none;
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
  margin-top: 3px;
  margin-left: 5%;
  font-size: 16px;
  color: #ffffff; /* Normal white text */
  height: fit-content;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s; /* Smooth transition for color, background and border */

  &:hover {
    background-color: transparent;
    color: #50aceb;
    border: 1px solid #50aceb; /* Same as the normal state of signup */
    outline: none;
    box-shadow: none;
  }

  cursor: pointer;
}

.nav-signup-button {
  background: none;
  white-space: nowrap; /* Prevent text from breaking into multiple lines */
  margin-top: 3px;
  font-size: 16px;
  color: #50aceb;
  height: fit-content;
  border: 1px solid #50aceb;
  border-radius: 3px;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s; /* Smooth transition for color, background and border */

  &:hover {
    background-color: #50aceb;
    color: #ffffff;
    border: 1px solid #50aceb;
    outline: none;
    box-shadow: none;
  }

  cursor: pointer;
}

.nav-list-wrap {
  position: relative;
}

.profile-icon {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-placeholder {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 50%;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

// Modify Ant Design Dropdown component styles
.dropdown-panel {
  background: #333333;

  :global {
    .ant-dropdown-menu-submenu,
    .ant-dropdown-menu-submenu-popup,
    .ant-dropdown-menu-light {
      //background: #333333;

      .ant-dropdown-menu-submenu,
      .ant-dropdown-menu-item {
        border-bottom: 1px solid #555555;
        background: #333333;

        &:hover {
          background: #50aceb;
        }

        &:last-of-type {
          border-bottom: 1px solid transparent;
        }
      }

      .ant-dropdown-menu-title-content {
        color: #fff;
      }

      .ant-dropdown-menu-item-only-child,
      .ant-dropdown-menu-submenu-title {
        color: #fff;
        padding: 12px 12px 5px;
        width: 150px;
        border-radius: 0;
        font-size: 18px;

        .ant-dropdown-menu-submenu-expand-icon,
        .ant-dropdown-menu-title-content {
          color: #fff;
          font-size: 18px;

          .ant-dropdown-menu-submenu-arrow-icon {
            color: #fff;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
