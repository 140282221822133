.wrap {
  height: 92px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  visibility: visible;
  transition: all 0.5s;
  padding: 0 16px;
  box-sizing: border-box;

  &.hide {
    visibility: hidden;
  }

  &.visible {
    visibility: visible;
    box-shadow: 0px -5px 20px;
    animation-name: fadeInDown;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .content {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    user-select: none; /* Prevent text selection */

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    @media (min-width: 1400px) {
      max-width: 1320px;
    }
  }
}
