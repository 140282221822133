.menu-item {
  width: 40px;
  height: 30px;
  font-size: 16px;
  color: #ffffff;
  background-color: #50aceb;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;

  &:hover {
    opacity: 0.9;
  }

  i {
    font-size: 18px;
  }
}

.nav-item {
  padding: 10px;
  border-top: 0px;
  border-bottom: 1px solid #555555;
  color: #ffffff;
  font-size: 18px;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background-color: #50aceb;
  }
}

.nav-menu-wrap {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 2;
  transition: all 0.3s;
  left: -240px;
  width: 240px;
  user-select: none; /* Prevent text selection */

  @media (min-width: 400px) {
    left: -300px;
    width: 300px;
  }

  &.open {
    left: 0;
  }
}
