.btn-comp {
  display: inline-block;
  height: 45px;
  //line-height: 40px;
  padding: 0px 20px;
  background-color: #50aceb;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  border: 1px solid #50aceb;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s; /* Smooth transition for color, background and border */
  &:hover {
    background-color: transparent;
    color: #50aceb;
    border: 1px solid #50aceb;
    outline: none;
    box-shadow: none;
  }
}
