/* ProfileMenu.module.scss */

.profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #2c2c2c; /* Dark background to match site */
  color: #fff; /* White text for contrast */
  border: 1px solid #444; /* Darker border */
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow */
  z-index: 1000;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  margin-top: 10px; /* Space between icon and menu */
}

.profile-menu.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.profile-menu-item {
  white-space: nowrap; // Prevent text from breaking into multiple lines
  padding: 12px 20px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.profile-menu-item:hover {
  background: #444; /* Slightly lighter background on hover */
}

.profile-menu-item:active {
  background: #555; /* Slightly darker background on active */
}

.profile-menu-item:not(:last-child) {
  border-bottom: 1px solid #555; /* Darker separator */
}

.profile-menu-item-text {
  vertical-align: middle;
  display: inline-block;
  font-family: "Arial", sans-serif; /* Match site's font */
  font-size: 14px; /* Consistent font size */
}
