.wrap {
  padding: 70px 0;
  text-align: center;
  background: #fff;
  margin-bottom: 0;

  @media (min-width: 990px) {
    margin-bottom: 697px;
  }

  h1 {
    margin-top: 0;
    font-size: 28px;

    @media (min-width: 576px) {
      font-size: 40px;
    }

    span {
      color: #50aceb;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    color: #777777;
  }

  .download {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 50px;

    .pic {
      width: 157px;
      cursor: pointer;
    }
  }
}